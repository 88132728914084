import { createClient } from "contentful";

class Contentful {
  #client = null;

  getClient() {
    if (!this.#client) {
      this.#client = createClient({
        space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
        accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
      });
    }

    return this.#client;
  }

  async fetch(query) {
    const entries = await this.getClient().getEntries(query);

    if (entries.items) {
      return entries.items;
    }

    return [];
  }

  async fetchValueOnly(query) {
    const results = await this.fetch(query);

    return results.map((item) => {
      return item.fields;
    });
  }
}

export default Contentful;
