import Carousel from "react-bootstrap/Carousel";
import Image from "next/image";

const RPCarousel = ({ items }) => {
  return (
    <Carousel className="rp-carousel bg-white col-12">
      {items.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <div className="rp-carousel-top text-center">
              <div className="mb-3">
                <Image
                  src={item.avatar_url}
                  width={64}
                  height={64}
                  className="rounded-circle"
                  unoptimized={true}
                  priority={true}
                />
              </div>
              <h6>{item.name}</h6>
              <p className="text-grey-1">{item.role}</p>
            </div>
            <p className="mb-0">{item.description}</p>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default RPCarousel;
