import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import StatItem from "./StatItem";

const Stats = ({ items, backgroundSplitColor = null, className = "" }) => {
  return (
    <section
      className={`stats-section position-relative ${
        backgroundSplitColor || ""
      } ${className}`.trim()}>
      <Container className="position-relative">
        <Row className="stat-item-container px-4 py-6">
          {items.map((item, index) => {
            return (
              <StatItem
                key={index}
                svg={item.svg}
                label={item.label}
                subheading={item.subheading}
              />
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Stats;
