import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";

const FindLocalPlayers = ({ title, paragraph, secondParagraph, Button }) => {
  return (
    <section className="find-local-players-section bg-grey-0 pt-7 py-md-7 ">
      <Container>
        <Row className="justify-content-between">
          <Col
            md={6}
            className="text-center position-relative order-2 order-md-1">
            <div className="px-6 px-md-0">
              <span className="image-bg-block d-block position-absolute w-100 bg-primary" />
              <Image
                src="/img/local-players.png"
                width={500}
                height={659}
                unoptimized={true}
              />
            </div>
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center order-1 order-md-2 mb-6 mb-md-0">
            <div className="w-100">
              <h2>{title}</h2>
              <p>{paragraph}</p>
              <p className="mb-4">{secondParagraph}</p>
              {Button && <Button />}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FindLocalPlayers;
