const HowItWorksItem = ({ index, children }) => {
  return (
    <div className="item p-3 d-flex align-items-center mb-2">
      <div>
        <span className="item-number rounded-circle me-3 text-white d-inline-block py-1">
          {index + 1}
        </span>
      </div>
      <span className="w-100 text-white">{children}</span>
    </div>
  );
};

export default HowItWorksItem;
