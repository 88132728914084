import HowItWorksItem from "./HowItWorksItem";

const HowItWorksList = ({ items }) => {
  return (
    <div className="how-it-works-list">
      {items.map((item, index) => {
        return (
          <HowItWorksItem key={index} index={index}>
            {item}
          </HowItWorksItem>
        );
      })}
    </div>
  );
};

export default HowItWorksList;
