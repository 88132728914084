import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "next/image";
import AppStoreLinks from "../Misc/AppStore/AppStoreLinks";
import { useAppContext } from "../../_context";

const AppBanner = () => {
  const [{ tx }] = useAppContext();

  return (
    <section className="app-banner-section bg-primary">
      <Container>
        <Row>
          <Col
            md={5}
            className="text-white app-banner-text d-flex align-items-center order-2 order-md-1">
            <div>
              <h2>{tx["Download now"]}!</h2>
              <p>
                {
                  tx[
                    "Download RacketPal now and join the UK’s fastest growing racket sports community."
                  ]
                }
              </p>
              <AppStoreLinks />
            </div>
          </Col>
          <Col md={7} className="position-relative order-1 order-md-2">
            <div className="image-container ps-md-4 ps-lg-7">
              <Image
                src="/img/app.png"
                width={722}
                height={600}
                unoptimized={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AppBanner;
