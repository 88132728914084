import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LeftRightContentText = ({
  title,
  children,
  centeredTitle = false,
  className = "",
}) => {
  return (
    <Container
      className={`${className.trim()} left-right-content-container-text section-padding-y`}>
      <Row className="justify-content-between">
        <Col
          md={4}
          className={centeredTitle ? "d-flex align-items-center" : ""}>
          <div className="w-100">
            <h2>{title}</h2>
            <span className="left-right-underline d-inline-block bg-primary"></span>
          </div>
        </Col>
        <Col md={6} className="text-black">
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default LeftRightContentText;
