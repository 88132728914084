import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import { useAppContext } from "../../_context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const FeaturedBy = () => {
  const [{ tx, locale }] = useAppContext();
  let mediaLogos;

  switch (locale) {
    case "cz":
      mediaLogos = [
        "cz_60.cz.png",
        "cz_CTK_ceskenoviny.png",
        "cz_CTK_protext.png",
        "cz_feedit.cz.png",
        "cz_fintree.png",
        "cz_jablickar.cz.png",
        "cz_letemsvetem_applem.png",
        "cz_metro.png",
        "cz_mobilmania.cz.png",
        "cz_protenis.png",
        "cz_samsung_magazine.png",
        "cz_svetandroida.png",
        "cz_trend_watcher.png",
        "cz_tv_barrandov.png",
        "cz_ytukej.cz.png",
        "cz_android_magazine.png",
      ].map((src) => ({ src, width: 130, height: 72 }));
      break;
    default:
      mediaLogos = [
        { src: "daily-mail.png", width: 195, height: 32 },
        { src: "forbes.png", width: 122, height: 32 },
        { src: "eu-startups.png", width: 108, height: 32 },
        { src: "republica.png", width: 161, height: 32 },
        { src: "the-recursive.png", width: 176, height: 32 },
        { src: "bdaily-news.png", width: 145, height: 32 },
        { src: "bne-intellinews.png", width: 336, height: 32 },
        { src: "ziarul-financiar.png", width: 195, height: 32 },
      ];
  }

  return (
    <section className="featured-by-section bg-grey-0">
      <Container>
        <Row>
          <Col className="text-center">
            <h5 className="text-grey-1">{tx["Featured by"]}</h5>
            <Swiper
              className="featured-by-icons d-flex m-auto flex-wrap no-gutters text-center col-md-10 align-items-center"
              slidesPerView={2}
              breakpoints={{ 768: { slidesPerView: 4 } }}
              autoplay={{
                delay: 2000,
              }}
              modules={[Autoplay]}
              initialSlide={0}>
              {mediaLogos.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="d-flex align-items-center justify-content-center">
                  <div className="px-2">
                    <Image
                      src={`/img/press/${item.src}`}
                      width={item.width}
                      height={item.height}
                      unoptimized={true}
                      priority={true}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedBy;
