import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../../_context";
import { useState, useEffect } from "react";
import AppStoreLinks from "../../Misc/AppStore/AppStoreLinks";
import Image from "next/image";
import { availableSportNames } from "../../../utilities/Constants";

const Hero = () => {
  const [{ tx, locale }] = useAppContext();

  const [index, setIndex] = useState(0);
  const delay = 6000;
  let i = index;
  let lookingForSuffix = "?";

  if (["cz"].includes(locale)) {
    lookingForSuffix = "";
  }

  useEffect(() => {
    const interval = setInterval(() => {
      i++;

      if (i >= availableSportNames.length) {
        i = 0;
      }

      setIndex(i);
    }, delay);

    return () => clearInterval(interval);
  });

  return (
    <>
      <section className="hero d-flex align-items-center position-relative overflow-hidden">
        {availableSportNames.map((sport, key) => {
          return (
            <Image
              key={key}
              src={`/img/heroes/home-${sport}.jpg`}
              layout="fill"
              objectFit="cover"
              objectPosition={key > 0 ? "center" : "top"}
              unoptimized={true}
              className={(key === index ? "active" : "") + " home-hero-image"}
            />
          );
        })}
        <div className="w-100 position-relative">
          <Container>
            <Row className="d-none d-md-flex align-items-center">
              <Col lg={5} className="text-white py-5">
                <h1>
                  {tx["Looking for someone to play"]}{" "}
                  <span className="text-primary px-2 py-1 bg-white rounded hero-line-height">
                    {tx[availableSportNames[index]] + lookingForSuffix}
                  </span>
                </h1>
                <p>
                  {
                    tx[
                      "Find local racket sports players and courts in your area in seconds."
                    ]
                  }
                </p>
                <AppStoreLinks filled={true} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="d-block d-md-none">
        <Container>
          <Row className="align-items-center">
            <Col md={5} className="py-5">
              <h1>
                {tx["Looking for someone to play"]}{" "}
                <span className="text-primary">
                  {tx[availableSportNames[index]] + lookingForSuffix}
                </span>
              </h1>
              <p>
                {
                  tx[
                    "Find local racket sports players and courts in your area in seconds."
                  ]
                }
              </p>
              <AppStoreLinks filled={false} white={false} stacked={true} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Hero;
