import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HowItWorksList from "./HowItWorksList";

const HowItWorks = ({ items, children, className = "" }) => {
  return (
    <section
      className={`how-it-works bg-dark-blue section-padding-y ${className}`}>
      <Container>
        <Row>
          <Col md={6} className="d-flex align-items-center">
            <div className="w-100 text-white">
              <Col lg={6} className="mx-auto">
                {children}
              </Col>
            </div>
          </Col>
          <Col md={6}>
            <HowItWorksList items={items} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowItWorks;
