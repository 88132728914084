import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RPCarousel from "./Misc/RPCarousel";

const Testimonials = ({
  carouselItems,
  title,
  subtitle,
  className = "",
  bgColour = "bg-grey-0",
  textColour = "",
}) => {
  return (
    <section
      className={`testimonial-section ${bgColour} position-relative ${className}`.trim()}>
      <Container>
        <Row className={`testimonial-title-row text-center ${textColour}`}>
          <Col>
            <h2 className="mb-3 font-size-20 font-size-md-40">{title}</h2>
            <p>{subtitle}</p>
          </Col>
        </Row>
        <Row>
          <RPCarousel items={carouselItems} />
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
