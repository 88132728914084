import Image from "next/image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LeftRightContentImage = ({
  src,
  title,
  paragraph,
  width,
  height,
  imageOnLeft = true,
  Button,
}) => {
  return (
    <Container className="left-right-content-container-image py-3">
      <Row className="justify-content-between">
        <Col
          md={6}
          className={`image-container order-md-${imageOnLeft ? "1" : "2"}`}>
          <Image
            src={src}
            layout="responsive"
            width={width}
            height={height}
            unoptimized={true}
          />
        </Col>
        <Col
          md={6}
          className={`order-md-${
            imageOnLeft ? "2" : "1"
          } d-flex align-items-center justify-content-center`}>
          <Col md={10} className="no-gutters">
            <h4>{title}</h4>
            <p>{paragraph}</p>
            {Button && <Button />}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default LeftRightContentImage;
