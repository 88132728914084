import LeftRightContentText from "../../LeftRightContentText";
import Button from "../../Misc/Button";
import LeftRightContentImage from "../../LeftRightContentImage";
import { useAppContext } from "../../../_context";

const AppViews = () => {
  const [{ tx: strings }] = useAppContext();

  return (
    <section className="app-views section-padding-bottom">
      <LeftRightContentText title={strings["Spend more time on the court"]}>
        <p>
          {
            strings[
              "We’re on a mission to make it quick and easy for racket sport players to find each other, connect and play together. It’s that simple."
            ]
          }
        </p>
        <p>
          {
            strings[
              "And once you’ve found the perfect sport partner, use RacketPal to find thousands of local courts and sport centres to book the best place to play your matches."
            ]
          }
        </p>
        <p className="mb-5">
          {
            strings[
              "From searching tennis players in London to finding the best badminton court, the RacketPal app is designed to help you spend more time on the court and less time missing out."
            ]
          }
        </p>
        <Button href="/about">{strings["Read our story"]}</Button>
      </LeftRightContentText>
      <LeftRightContentImage
        src="/img/players.png"
        title={strings["Find local players"]}
        paragraph={
          strings[
            "Looking for someone to play against? Other players in your area are just a tap away. It’s quick and easy to find and connect with local players who are up for a match."
          ]
        }
        width={576}
        height={454}
      />
      <LeftRightContentImage
        src="/img/chat.png"
        title={strings["Chat and organise matches"]}
        paragraph={
          strings[
            "Use our in-app chat to quickly connect with players and organise matches together. You can also organise group matches by chatting to multiple players with a group chat."
          ]
        }
        width={576}
        height={441}
        imageOnLeft={false}
      />
      <LeftRightContentImage
        src="/img/leagues.png"
        title={strings["Join local leagues"]}
        paragraph={
          strings[
            "Compete with other players of a similar skill level by signing up to a local league – and potentially win prize money. You can join existing leagues in your area, or create your own."
          ]
        }
        width={576}
        height={405}
      />
      <LeftRightContentImage
        src="/img/hire.png"
        title={strings["Hire a coach"]}
        paragraph={
          strings[
            "Need to up your game? Hire an experienced coach through the app and get ready to improve your technique. Or find a sparring partner to practice with on the court."
          ]
        }
        width={576}
        height={466}
        imageOnLeft={false}
      />
      <LeftRightContentImage
        src="/img/local-courts.png"
        title={strings["Find local courts"]}
        paragraph={
          strings[
            "Browse local courts and sports venues to find the perfect place to play in your area. And by adding favourite clubs to your profile you can meet other players who also play there."
          ]
        }
        width={576}
        height={490}
      />
    </section>
  );
};

export default AppViews;
