import Button from "./Misc/Button";
import LeftRightContentText from "./LeftRightContentText";
import { useAppContext } from "../_context";

const LeaveFeedback = () => {
  const [{ tx }] = useAppContext();

  return (
    <LeftRightContentText title={tx["Help us be the best"]}>
      <p className="mb-5">
        {
          tx[
            "Got an idea? Think there's something we can improve? We'd love to hear from you! Help us make sure that RacketPal is the best app it can be, with your feedback."
          ]
        }
      </p>
      <Button href="/contact?reason=suggestions">
        {tx["Tell us what you think!"]}
      </Button>
    </LeftRightContentText>
  );
};

export default LeaveFeedback;
