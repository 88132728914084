import Col from "react-bootstrap/Col";
import Image from "next/image";

const StatItem = ({ svg, label, subheading }) => {
  return (
    <Col xs={6} md={3} className="text-center py-3 py-md-0">
      <div>
        <Image src={svg} width={40} height={40} unoptimized={true} />
      </div>
      <h3>{label}</h3>
      <p className="text-grey-1 text-uppercase mb-0">{subheading}</p>
    </Col>
  );
};

export default StatItem;
